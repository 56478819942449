import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';

import { router } from '@/routes/Router';

import { config } from '@/config';
import { main } from '@/configuration';
import { handleLoginRedirect } from '@/configuration/login/redirect';

import { ErrorBoundary } from '@/components/ErrorBoundary';

import '@/index.css';

const renderApplication = () => {
  const queryClient = new QueryClient();
  createRoot(document.getElementById('root') as HTMLElement).render(
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
  handleLoginRedirect();
} else {
  main(renderApplication);
}
