const asBool = (value: string | undefined): boolean => value === 'true';

export interface ConfigState {
  backend: {
    AUTHENTICATION_SERVICE: string | undefined;
    MENU_SERVICE: string | undefined;
  };
  homeRoute: string | undefined;
  id: string | undefined;
  login: {
    authority: string | undefined;
    clientId: string | undefined;
    clientSecret: string | undefined;
    oauthScope: string[];
    mockLocale: string | undefined;
    preventRedirect: boolean;
    redirectUri: string | undefined;
    silentRedirectUri: string | undefined;
  };
  serviceVersion: string;
  serviceEnvironment: string;
  logoutUri: string | undefined;
  googleAnalytics: {
    gtm_id: string | undefined;
    gtm_auth: string | undefined;
    gtm_preview: string | undefined;
  };
}

export const config: ConfigState = {
  backend: {
    AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
    MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
  },
  homeRoute: import.meta.env.VITE_HOME_ROUTE,
  id: import.meta.env.VITE_ID,
  login: {
    authority: import.meta.env.VITE_LOGIN_AUTHORITY,
    clientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
    // TO be used only locally
    clientSecret: import.meta.env.DEV
      ? import.meta.env.VITE_LOGIN_CLIENT_SECRET
      : undefined,
    oauthScope: ['openid', 'profile', 'email'],
    mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
    preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
    redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
    silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
  },
  serviceVersion: APP_VERSION,
  serviceEnvironment: import.meta.env.MODE,
  logoutUri: import.meta.env.VITE_LOGOUT_URI,
  googleAnalytics: {
    gtm_id: import.meta.env.VITE_GTM_ID,
    gtm_auth: import.meta.env.VITE_GTM_AUTH,
    gtm_preview: import.meta.env.VITE_GTM_PREVIEW,
  },
};
