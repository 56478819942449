import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Dialog,
  ListMenu,
  OverlayTrigger,
  Tooltip,
} from '@rio-cloud/rio-uikit';

import UserManualDialogContent from '@/features/userManual/components/UserManualDialogContent';
import { pagesForUserManualDialog } from '@/features/userManual/utils';

import { DialogType, useModal } from '@/hooks/useModal';

import { cn, t } from '@/lib/utils';

export const UserManualButton = () => {
  const { openDialog } = useModal();

  const [show, setShow] = useState(false);
  const [selectedPageIndex, setSelectedPageIndex] = useState<string | null>(
    null
  );

  const handleMenuItemClick = useCallback((pageKey: string) => {
    setSelectedPageIndex(pageKey);
  }, []);

  const menuItems = useMemo(
    () => [
      {
        group: <FormattedMessage id="userManual.menu.title" />,
        navItems: pagesForUserManualDialog.map(({ keyPage }) => ({
          key: keyPage,
          item: (
            <span
              className={cn(
                keyPage === selectedPageIndex && 'active !border-0 !border-l-2',
                'hover:!border-0 hover:!border-l-2'
              )}
              onClick={() => handleMenuItemClick(keyPage)}
            >
              <FormattedMessage id={`userManual.${keyPage}.title`} />
            </span>
          ),
        })),
      },
    ],
    [t, selectedPageIndex, handleMenuItemClick]
  );

  useEffect(() => {
    if (selectedPageIndex == null) {
      return;
    }

    const selectedPage = pagesForUserManualDialog.find(
      (page) => page.keyPage === selectedPageIndex
    );

    openDialog({
      type: DialogType.SPLIT,
      show,
      onClose: () => {
        setSelectedPageIndex(null);
        setShow(false);
      },
      title: <FormattedMessage id="userManual.modalTitle" />,
      bsSize: Dialog.SIZE_XL,
      leftContent: (
        <ListMenu menuItems={menuItems} groupClassName="padding-left-0" />
      ),
      rightContent: selectedPage && (
        <UserManualDialogContent
          keyPage={selectedPage.keyPage}
          imageSrc={selectedPage.imageSrc}
          listKeys={selectedPage.listKeys}
        />
      ),
    });
  }, [selectedPageIndex, openDialog, menuItems, show]);

  const openDialogHelper = useCallback(() => {
    setSelectedPageIndex(pagesForUserManualDialog[0].keyPage);
    setShow(true);
  }, []);

  return (
    <OverlayTrigger
      placement={'bottom'}
      overlay={
        <Tooltip id="tooltip" allowOnTouch>
          <FormattedMessage id="general.userManual" />
        </Tooltip>
      }
    >
      <span
        className="text-size-h3 icon rioglyph rioglyph-info-sign pl-2"
        data-test-id="user-manual-btn"
        onClick={openDialogHelper}
      />
    </OverlayTrigger>
  );
};
