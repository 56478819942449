import type { ComponentProps } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

type NavigationLinkProps = ComponentProps<typeof NavLink>;
export const NavigationLink = ({
  children,
  className,
  ...props
}: NavigationLinkProps) => {
  const { pathname } = useLocation();
  const topLevelSegment = props.to.toString().split('/').slice(0, 2).join('/');
  const isActive = pathname.startsWith(topLevelSegment);

  return (
    <NavLink
      className={`${isActive ? 'active' : undefined} ${className}`}
      {...props}
    >
      {children}
    </NavLink>
  );
};
