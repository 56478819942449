import { z } from 'zod';

export const permissionsEnum = z.enum([
  'emsp-dashboard',
  'emsp-onboarding',
  'emsp-cards.read',
  'emsp-cards.write',
  'emsp-contract.read',
  'emsp-contract.write',
  'emsp-charging-sessions.read',
  'emsp-invoices.read',
]);

export const featureEnum = z.enum([
  'ACCOUNT_ELIGIBLE',
  'ACCOUNT_ALLOWED_TO_FINISH_ONBOARDING',
]);

export const AccountDto = z.object({
  address: z.object({
    firstLine: z.string(),
    secondLine: z.string().optional(),
    thirdLine: z.string().optional(),
    postalCode: z.string(),
    city: z.string(),
    countryCode: z.string(),
  }),
  features: z.optional(z.string().array()),
  fleetMonitorURL: z.string(),
  isOnboarded: z.optional(z.boolean()),
  name: z.string(),
  permissions: z.optional(z.string().array()),
  vatNumber: z.string(),
});

export type AccountType = z.infer<typeof AccountDto>;
