import { config } from '@/config';
import TagManager from 'react-gtm-module';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export type GTMConfig = {
  gtmId: string;
  gtmAuth: string;
  gtmPreview: string;
};

export type BaseAnalyticsEvent = {
  event: string;
  trigger?: 'click' | 'visibility';
  flow_name?: string;
  element_name?: string;
  element_state?: string;
  [key: string]: any;
};

export type AnalyticsEvent = BaseAnalyticsEvent & {
  timestamp: string;
};

export type PageViewEvent = {
  event: 'pageview';
  page_location: string;
  page_title: string;
};

export type DebugConfig = {
  enabled: boolean;
  logStyle?: string;
  verbose?: boolean;
};

let debugConfigInstance: DebugConfig | null = null;

export const createDebugConfig = (): DebugConfig => {
  if (!debugConfigInstance) {
    debugConfigInstance = {
      enabled: process.env.NODE_ENV === 'development',
      logStyle:
        'background: #2563eb; color: white; padding: 2px 4px; border-radius: 2px;',
      verbose: true,
    };
  }
  return debugConfigInstance;
};

const createDebugLogger = (debugConfig: DebugConfig) => ({
  log: (message: string, data?: any) => {
    if (!debugConfig.enabled) return;

    if (debugConfig.verbose && data) {
      console.groupCollapsed(
        `%c Analytics Debug %c ${message}`,
        debugConfig.logStyle || '',
        'color: inherit'
      );
      console.log('Event Data:', data);
      console.log('Timestamp:', new Date().toISOString());
      console.log('Current Path:', window.location.pathname);
      console.trace('Stack Trace:');
      console.groupEnd();
    } else {
      console.log(
        `%c Analytics Debug %c ${message}`,
        debugConfig.logStyle || '',
        'color: inherit',
        data || ''
      );
    }
  },
  warn: (message: string, data?: any) => {
    if (!debugConfig.enabled) return;
    console.warn('Analytics Debug - Warning:', message, data || '');
  },
  error: (message: string, error?: any) => {
    if (!debugConfig.enabled) return;
    console.error('Analytics Debug - Error:', message, error || '');
  },
});

const isValidConfig = (config: Partial<GTMConfig>): config is GTMConfig => {
  return Boolean(config.gtmId && config.gtmAuth && config.gtmPreview);
};

const createGTMConfig = (): Partial<GTMConfig> => ({
  gtmId: config.googleAnalytics.gtm_id,
  gtmAuth: config.googleAnalytics.gtm_auth,
  gtmPreview: config.googleAnalytics.gtm_preview,
});

// Initialize GTM with enhanced error handling
export const initializeGTM = (
  config: Partial<GTMConfig>,
  debugConfig: DebugConfig
): boolean => {
  const debug = createDebugLogger(debugConfig);

  if (!isValidConfig(config)) {
    debug.warn('Invalid GTM configuration', config);
    return false;
  }

  try {
    TagManager.initialize({
      gtmId: config.gtmId,
      auth: config.gtmAuth,
      preview: config.gtmPreview,
    });
    debug.log('GTM initialized successfully', config);
    return true;
  } catch (error) {
    debug.error('Failed to initialize GTM', error);
    return false;
  }
};

// Event tracking functions
export const pushToDataLayer = (
  event: AnalyticsEvent,
  debugConfig: DebugConfig
): void => {
  const debug = createDebugLogger(debugConfig);

  if (window.dataLayer) {
    window.dataLayer.push(event);
    debug.log('Event pushed to dataLayer', event);
  } else {
    debug.warn('DataLayer not found. Event not pushed:', event);
  }
};

const createEvent = (baseEvent: BaseAnalyticsEvent): AnalyticsEvent => ({
  ...baseEvent,
  timestamp: new Date().toISOString(),
});

export const trackEvent = (
  baseEvent: BaseAnalyticsEvent,
  debugConfig: DebugConfig
): void => {
  const eventWithTimestamp = createEvent(baseEvent);
  pushToDataLayer(eventWithTimestamp, debugConfig);
};

export const trackPageView = (
  pageData: Omit<PageViewEvent, 'event'>,
  debugConfig: DebugConfig
): void => {
  trackEvent(
    {
      event: 'pageview',
      ...pageData,
    },
    debugConfig
  );
};

export const trackCustomEvent = (
  eventName: string,
  {
    trigger,
    flow_name,
    element_name,
    element_state,
    ...otherData
  }: Omit<BaseAnalyticsEvent, 'event'>,
  debugConfig: DebugConfig
): void => {
  trackEvent(
    {
      event: eventName,
      trigger,
      flow_name,
      element_name,
      element_state,
      ...otherData,
    },
    debugConfig
  );
};

export const initializeAnalytics = (): boolean => {
  const debugConfig = createDebugConfig();
  const config = createGTMConfig();
  return initializeGTM(config, debugConfig);
};
