import { create } from 'zustand';

import { DEFAULT_LOCALE, getSupportedLocale } from '@/configuration/lang/lang';

import messagesEN from '@/features/translations/en-GB.json';

export type DisplayMessages = Record<string, string>;

type CombinedMessages = Record<string, DisplayMessages>;

export type LangState = {
  allMessages: CombinedMessages;
  displayMessages: DisplayMessages | undefined;
  displayLocale: string | undefined;
  localeChanged: (locale: string) => void;
  displayMessagesFetched: (messages: MessagesPayload) => void;
};

type MessagesPayload = {
  locale: string;
  displayMessages: DisplayMessages;
};

const defaultMessages = {
  [DEFAULT_LOCALE]: messagesEN,
};

export const useLocaleStore = create<LangState>()((set) => ({
  allMessages: defaultMessages,
  displayMessages: undefined,
  displayLocale: undefined,
  localeChanged: (locale: string) =>
    set((state) => {
      const displayLocale = getSupportedLocale(locale);
      return {
        displayLocale,
        displayMessages: state.allMessages[displayLocale],
      };
    }),
  displayMessagesFetched: (messages: MessagesPayload) =>
    set((state) => {
      const { locale, displayMessages } = messages;
      return {
        allMessages: {
          ...state.allMessages,
          [locale]: displayMessages,
        },
        displayMessages,
        displayLocale: locale,
      };
    }),
}));
