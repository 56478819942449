import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  createDebugConfig,
  trackPageView,
} from '@/configuration/setup/googleAnalytics';

import { pageTitles } from '@/routes/Router';

export const usePageTracking = (): void => {
  const location = useLocation();
  const debugConfig = createDebugConfig();

  useEffect(() => {
    const page = pageTitles.find(({ page }) =>
      location.pathname.startsWith(page)
    );

    if (page) {
      trackPageView(
        {
          page_location: `${window.location.origin}${location.pathname}${location.search}${location.hash}`,
          page_title: page.title,
        },
        debugConfig
      );
    }
  }, [location]);
};
