import { datadogLogs } from '@datadog/browser-logs';
import { create } from 'zustand';

export type UserProfile = {
  sub?: string;
  azp?: string;
  account?: string;
  givenName?: string;
  familyName?: string;
  name?: string;
  locale?: string;
  email?: string;
};

export type AccountState = {
  hasUserSessionEverExpired: boolean;
  userProfile: UserProfile | null;
  userSessionExpired: boolean;
  setUserProfile: (profile: UserProfile | null) => void;
  setUserSessionExpired: () => void;
  setUserSessionRenewed: () => void;
};

export const useAccountStore = create<AccountState>()((set) => ({
  hasUserSessionEverExpired: false,
  userProfile: null,
  userSessionExpired: false,
  setUserProfile: (profile: UserProfile | null) =>
    set((state) => {
      datadogLogs.setUser({
        id: profile?.sub,
      });
      return { userProfile: profile };
    }),
  setUserSessionExpired: () =>
    set(() => ({
      hasUserSessionEverExpired: true,
      userSessionExpired: true,
    })),
  setUserSessionRenewed: () =>
    set(() => ({
      userSessionExpired: false,
    })),
}));
