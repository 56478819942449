import { create } from 'zustand';

export interface AppState {
  sessionExpiredAcknowledged: boolean;
  hideSessionExpiredDialog: () => void;
}

export const useAppStore = create<AppState>()((set) => ({
  sessionExpiredAcknowledged: false,
  hideSessionExpiredDialog: () =>
    set((state) => ({ ...state, sessionExpiredAcknowledged: true })),
}));
