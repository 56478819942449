import { UserProfile } from '@/stores/accountStore';
import { AccessToken } from '@/stores/tokenStore';

import { createApiService } from '@/data/service';

import { logError } from '@/lib/logger';

import { AccountDto, AccountType } from './dto';

export const getAccount = async (
  accessToken: AccessToken,
  userProfile: UserProfile | null
): Promise<AccountType | undefined> => {
  if (!userProfile?.account || !accessToken) {
    logError({
      message: 'User profile, account, or access token not found.',
    });
    return undefined;
  }

  const axios = createApiService({
    headers: {
      'X-Account-ID': userProfile.account,
      Authorization: `Bearer ${accessToken}`,
    },
  });

  try {
    const result = await axios.get('/admin/account');

    if (!result.data) {
      throw new Error('No data received from the server');
    }

    const accountData = AccountDto.safeParse(result.data);

    if (!accountData.success) {
      throw new Error(`Invalid account data: ${accountData.error.message}`);
    }

    return accountData.data;
  } catch (error) {
    logError({
      message: 'Could not fetch account',
      error: error instanceof Error ? error : new Error(String(error)),
    });
    return undefined;
  }
};
