import type { UserProfile as Profile } from 'oidc-client-ts';
import { create } from 'zustand';

import { accessToken } from '@/configuration/tokenHandling/accessToken';

export type AccessToken = string | undefined | null;

interface TokenState {
  accessToken: AccessToken;
  idToken: Profile | null;
  updateToken: (token: AccessToken) => void;
  updateIdToken: (idToken: Profile | null) => void;
}

export const useTokenStore = create<TokenState>()((set) => ({
  accessToken: accessToken.getAccessToken(),
  idToken: null,
  updateToken: (token: AccessToken) => set((state) => ({ accessToken: token })),
  updateIdToken: (token: Profile | null) =>
    set((state) => ({ idToken: token })),
}));
