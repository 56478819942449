export interface IPagesForUserManualDialog {
  keyPage: string;
  imageSrc: string;
  listKeys: string[];
}

export const pagesForUserManualDialog: IPagesForUserManualDialog[] = [
  {
    keyPage: 'overview',
    imageSrc: '/overview.webp',
    listKeys: ['description1', 'description2', 'description3'],
  },
  {
    keyPage: 'chargingCards',
    imageSrc: '/chargingCards.webp',
    listKeys: [
      'description1',
      'description2',
      'description3',
      'description4',
      'description5',
    ],
  },
  {
    keyPage: 'activateCard',
    imageSrc: '/activateCard.webp',
    listKeys: ['description1', 'description2', 'description3'],
  },
  {
    keyPage: 'chargingCardAlias',
    imageSrc: '/chargingCardAlias.webp',
    listKeys: ['description1', 'description2', 'description3'],
  },
  {
    keyPage: 'orderChargingCard',
    imageSrc: '/orderChargingCard.webp',
    listKeys: ['description1', 'description2', 'description3', 'description4'],
  },
  {
    keyPage: 'contractInformation',
    imageSrc: '/contractInformation.webp',
    listKeys: ['description1', 'description2', 'description3', 'description4'],
  },
  {
    keyPage: 'invoiceDetails',
    imageSrc: '/invoiceDetails.webp',
    listKeys: ['description1', 'description2', 'description3', 'description4'],
  },
  {
    keyPage: 'chargingSessions',
    imageSrc: '/chargingSessions.webp',
    listKeys: [
      'description1',
      'description2',
      'description3',
      'description4',
      'description5',
      'description6',
      'description7',
    ],
  },
  {
    keyPage: 'detailChargingSessions',
    imageSrc: '/detailChargingSessions.webp',
    listKeys: ['description1', 'description2', 'description3'],
  },
  {
    keyPage: 'invoices',
    imageSrc: '/invoices.webp',
    listKeys: [
      'description1',
      'description2',
      'description3',
      'description4',
      'description5',
      'description6',
      'description7',
    ],
  },
];
