import { CustomStepType } from '@/features/guidedTour/types';

export const steps: CustomStepType[] = [
  {
    selector: 'body',
    highlightedSelectors: [
      'li[data-nav-item-key="overview"]',
      'li[data-nav-item-key="charging"]',
      'li[data-nav-item-key="invoices"]',
      'li[data-nav-item-key="administration"]',
    ],
    key: 'guidedTour.step.overview',
    route: '/dashboard',
  },
  {
    selector: 'div[data-testid="map-view-panel"]',
    key: 'guidedTour.step.overview.chargingStation',
    route: '/dashboard',
  },
  {
    selector: 'body',
    highlightedSelectors: [
      'li[data-nav-item-key="overview"]',
      'li[data-nav-item-key="charging"]',
      'li[data-nav-item-key="invoices"]',
      'li[data-nav-item-key="administration"]',
    ],
    key: 'guidedTour.step.charging',
    route: '/charging/sessions',
  },

  {
    selector: 'body',
    highlightedSelectors: [
      'li[data-nav-item-key="overview"]',
      'li[data-nav-item-key="charging"]',
      'li[data-nav-item-key="invoices"]',
      'li[data-nav-item-key="administration"]',
    ],
    key: 'guidedTour.step.invoices',
    route: '/invoices',
  },
  {
    selector: 'body',
    highlightedSelectors: [
      'li[data-nav-item-key="overview"]',
      'li[data-nav-item-key="charging"]',
      'li[data-nav-item-key="invoices"]',
      'li[data-nav-item-key="administration"]',
      'li[data-nav-item-key="administration.cards"]',
    ],
    key: 'guidedTour.step.administration.cards',
    route: '/administration/cards',
  },
  {
    selector: 'body',
    highlightedSelectors: [
      'li[data-nav-item-key="overview"]',
      'li[data-nav-item-key="charging"]',
      'li[data-nav-item-key="invoices"]',
      'li[data-nav-item-key="administration"]',
      'li[data-nav-item-key="administration.contract"]',
    ],
    key: 'guidedTour.step.administration.contract',
    route: '/administration/contract',
  },
];
