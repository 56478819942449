import { IntlShape } from 'react-intl';
import { create } from 'zustand';

import { AccountType, featureEnum } from '@/data/account/dto';

export type UserState = {
  account: AccountType | null;
  canOnboard: boolean;
  isEligible: boolean;
  setAccount: (profile: AccountType | null) => void;
  countryLocale: string;
  countryIntl: IntlShape | undefined;
  setCountryIntl: (
    locale: string | undefined,
    intl: IntlShape | undefined
  ) => void;
};

const checkFeature = (
  account: AccountType | null,
  feature: string
): boolean => {
  return !!account?.features?.find((f) => f === feature);
};

export const checkPermission = (
  account: AccountType | null,
  permission: string
): boolean => {
  return !!account?.permissions?.find((f) => f === permission);
};

export const useUserStore = create<UserState>()((set) => ({
  account: null,
  canOnboard: false,
  isEligible: false,
  setAccount: (profile: AccountType | null) =>
    set(() => {
      return {
        account: profile,
        canOnboard: checkFeature(
          profile,
          featureEnum.enum.ACCOUNT_ALLOWED_TO_FINISH_ONBOARDING
        ),
        isEligible: checkFeature(profile, featureEnum.enum.ACCOUNT_ELIGIBLE),
      };
    }),
  countryLocale: '',
  countryIntl: undefined,
  setCountryIntl: (locale: string | undefined, intl: IntlShape | undefined) =>
    set(() => ({
      countryLocale: locale,
      countryIntl: intl,
    })),
}));
