import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { logError } from '@/lib/logger';

type HttpMethod = 'GET' | 'HEAD' | 'PUT' | 'POST';

interface ApiService {
  get: (url: string, options?: AxiosRequestConfig) => Promise<AxiosResponse>;
  head: (url: string, options?: AxiosRequestConfig) => Promise<AxiosResponse>;
  post: (
    url: string,
    data: unknown,
    options?: AxiosRequestConfig
  ) => Promise<AxiosResponse>;
  put: (
    url: string,
    data: unknown,
    options?: AxiosRequestConfig
  ) => Promise<AxiosResponse>;
  withHeaders: (headers: Record<string, string>) => ApiService;
  withBearerToken: (token: string) => ApiService;
  withBasicToken: (token: string) => ApiService;
}

const createRequest =
  (
    axiosInstance: AxiosInstance,
    baseUrl: string,
    defaultOptions: AxiosRequestConfig = {}
  ) =>
  (
    method: HttpMethod,
    url: string,
    options: AxiosRequestConfig = {},
    data?: any
  ): Promise<AxiosResponse> => {
    const config: AxiosRequestConfig = {
      method,
      url: baseUrl + url,
      ...defaultOptions,
      ...options,
      data,
    };
    return axiosInstance(config).catch((error: Error) => {
      logError({
        message: `${method} to ${url} failed: ${error.message}`,
        context: {
          config,
          url,
          method,
          options,
          data,
        },
        error,
      });
      throw error;
    });
  };

const convertHeaders = (
  headers?: AxiosRequestConfig['headers']
): Record<string, string> => {
  const result: Record<string, string> = {};
  if (headers && typeof headers === 'object') {
    Object.entries(headers).forEach(([key, value]) => {
      if (value !== undefined) {
        result[key] = String(value);
      }
    });
  }
  return result;
};

export const createApiService = (
  defaultOptions: AxiosRequestConfig = {}
): ApiService => {
  const axiosInstance = axios.create({
    headers: convertHeaders(defaultOptions.headers),
  });

  const baseUrl = import.meta.env.PROD
    ? import.meta.env.VITE_EMSP_API_URL
    : '/api';
  const request = createRequest(axiosInstance, baseUrl, defaultOptions);

  return {
    get: (url: string, options?: AxiosRequestConfig) =>
      request('GET', url, options),
    head: (url: string, options?: AxiosRequestConfig) =>
      request('HEAD', url, options),
    post: (url: string, data: any, options?: AxiosRequestConfig) =>
      request('POST', url, options, data),
    put: (url: string, data: any, options?: AxiosRequestConfig) =>
      request('PUT', url, options, data),
    withHeaders: (headers: Record<string, string>) =>
      createApiService({ ...defaultOptions, headers }),
    withBearerToken: (token: string) =>
      createApiService({
        ...defaultOptions,
        headers: {
          ...defaultOptions.headers,
          Authorization: `Bearer ${token}`,
        },
      }),
    withBasicToken: (token: string) =>
      createApiService({
        ...defaultOptions,
        headers: {
          ...defaultOptions.headers,
          Authorization: `Basic ${token}`,
        },
      }),
  };
};
