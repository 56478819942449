import React, { ComponentProps } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

type NavigationLinkProps = ComponentProps<typeof NavLink>;
export const SubNavigationLink = ({
  children,
  className,
  ...props
}: NavigationLinkProps) => {
  const { pathname } = useLocation();
  const isActive = pathname.startsWith(props.to.toString());

  return (
    <NavLink
      className={`${isActive ? 'active' : undefined} ${className}`}
      {...props}
    >
      {children}
    </NavLink>
  );
};
