import IframeResizer from 'iframe-resizer-react';
import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { config } from '@/config';

import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import type { ModulePropType } from '@rio-cloud/rio-uikit/components/applicationHeader/ApplicationHeader';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';

import { useLocaleStore } from '@/stores/localeStore';
import { useUserStore } from '@/stores/userStore';

import { UserManualButton } from '@/features/userManual/components/UserManualButton';

const DevUserMenu = lazy(() => import('@/components/DevUserMenu'));

export interface AppHeaderProps {
  navItems: ModulePropType[];
}

const AppHeader = ({ navItems }: AppHeaderProps) => {
  const environment = import.meta.env.PROD ? 'production' : 'local';
  const { account } = useUserStore();
  const { displayLocale } = useLocaleStore();

  const renderUserMenu = () => {
    if (config.serviceEnvironment === 'production') {
      return (
        <DefaultUserMenu
          key="user-menu"
          environment={environment}
          locale={displayLocale}
        />
      );
    }

    return (
      // biome-ignore lint/complexity/noUselessFragments: <explanation>
      <Suspense fallback={<></>}>
        <DevUserMenu key="dev-user-menu" />
      </Suspense>
    );
  };

  const actionBarItems = [
    account?.isOnboarded ? <UserManualButton key="user-manual" /> : null,
    renderUserMenu(),
  ].filter(Boolean);

  return (
    <ApplicationHeader
      className={'pb-[0.5px]'}
      label={<FormattedMessage id="userManual.modalTitle" />}
      appNavigator={
        environment === 'production' ? (
          <IframeResizer
            className="iFrameResizer"
            src={config.backend.MENU_SERVICE}
          />
        ) : null
      }
      homeRoute={<Link to={config.homeRoute || ''} />}
      navItems={navItems}
      actionBarItems={actionBarItems}
    />
  );
};

export default AppHeader;
