import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { useNavigate } from 'react-router';

import { DEFAULT_ROUTE } from '@/routes/Router';

import { t } from '@/lib/utils';

const NotFoundError = () => {
  const navigate = useNavigate();
  return (
    <NotFoundState
      headline={t('general.notFound.heading')}
      message={t('general.notFound.message')}
      buttons={[
        {
          text: t('general.notFound.button'),
          onClick: () => {
            navigate(DEFAULT_ROUTE);
          },
        },
      ]}
    />
  );
};

export default NotFoundError;
